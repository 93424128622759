<template>
  <div class="cooperation">
    <header class="head"></header>

    <section class="banner">
      <div class="title">合作企业</div>
      <el-carousel
        :interval="5000"
        arrow="never"
        :loop="true"
        :autoplay="true"
        indicator-position="none"
        ref="carousel"
        @change="handleCarouselChange"
      >
        <el-carousel-item
          v-for="(item, index) in cooperationList.banner"
          :key="item.title"
        >
          <div class="carousel-main" @mouseleave="handleMouseOut">
            <div class="left" :class="`left-img-${index+1}`"></div>
            <div class="right">
              <div class="top">
                <!-- <span v-if="index == 2" class="right-icon-sd">神盾科技</span> -->
                <span :class="`right-icon-${index}`"></span>
              </div>
              <div class="bottom">
                <div class="text-item" v-for="(text, i) in item.text" :key="i">
                  {{ text }}
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="toggle">
        <div
          class="toggle-item"
          :class="{ active: index == carouselIndex }"
          v-for="(item, index) in cooperationList.banner"
          :key="item.title"
          @click="handleToggle(index)"
        >
          <span v-if="index == 2" class="right-icon-sd">神盾科技</span>
          <span v-else :class="`right-icon-${index}`"></span>
        </div>
      </div>
    </section>

    <section class="partner">
      <div class="title">服务对象</div>
      <div class="main">
        <div class="main-top">
          <div
            class="item"
            v-for="(item, index) in cooperationPartner"
            :key="index"
          >
            <img
              :src="require(`../assets/homecopy/hands_${item.iconIndex}.png`)"
            />
          </div>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="changePage"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { cooperationList, cooperationPartner } from "@/baseData/index";

export default {
  data() {
    return {
      carouselIndex: 0,
      cooperationList,
      cooperationPartner: [],
      pageNo: 1,
      pageSize: 9,
      total: 0,
    };
  },

  created() {
    this.getCooperationPartner();
  },

  methods: {
    handleCarouselChange(index) {
      this.carouselIndex = index;
    },

    handleMouseOut() {
      let index = this.carouselIndex > 2 ? 0 : this.carouselIndex + 1;
      this.$refs.carousel.setActiveItem(index);
    },

    handleToggle(index) {
      this.$refs.carousel.setActiveItem(index);
    },

    getCooperationPartner() {
      const start = (this.pageNo - 1) * this.pageSize;
      this.cooperationPartner = cooperationPartner.slice(
        start,
        start + this.pageSize
      );
      this.total = cooperationPartner.length;
    },

    changePage(pageNo) {
      console.log("changePage", pageNo);
      this.pageNo = pageNo;
      this.getCooperationPartner();
    },
  },
};
</script>

<style lang="less" scoped>
.cooperation {
  background-color: #ffffff;
  .head {
    height: 700px;
    background: url("../assets/cooperation/cooperation.png") no-repeat center
      center;
    background-size: cover;
  }
  .banner {
    height: 500px;
    margin: 0 20px;
    text-align: center;
    transition: all 0.5s ease;
    .title {
      margin: 20px auto;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
    }
    /deep/ .el-carousel__container {
      height: 330px;
    }
    .carousel-main {
      display: flex;
      justify-content: center;
      max-width: 1400px;
      margin: 0 auto;
      font-size: 15px;
      .left {
        flex-shrink: 0;
        width: 400px;
        height: 300px;
        background: url("../assets/cooperation/cooperation-left.jpg") no-repeat
          center center / 110% 110%;
      }
      .left-img-2 {
        background: url("../assets/cooperation/cooperation-left2.png") no-repeat
          center center / 110% 110% !important;
      }
      .right {
        padding: 5px 20px;
        .bottom {
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 10;
          margin-top: 10px;
          .text-item {
            margin-bottom: 10px;
          }
        }
      }
    }
    .toggle {
      display: flex;
      justify-content: center;
      gap: 100px;
      margin-top: 20px;
      &-item {
        cursor: pointer;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        padding: 5px 0;
        &.active {
          border: 1px solid #7db3d9;
          border-radius: 2px;
        }
      }
    }
    .right-icon-sd,
    .right-icon-0,
    .right-icon-1 {
      width: 130px;
      height: 50px;
      display: block;
    }
    .right-icon-sd {
      background-color: #7db3d9;
      color: #ffffff;
      font-size: 17px;
      width: 120px;
      height: 40px;
      line-height: 40px;
      border-radius: 5px;
    }
    .right-icon-0 {
      background: url("../assets/cooperation/banner-item-1.png") no-repeat
        center center / 100% auto;
      background-position: center 1px;
    }
    .right-icon-1 {
      background: url("../assets/cooperation/banner-item-2.png") no-repeat
        center center / 100% auto;
    }
  }
  .partner {
    .title {
      margin: 20px auto 30px;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
    }
    .main {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;
      .main-top {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1400px;
        gap: 70px;
        .item {
          width: 22%;
          height: auto;
          box-shadow: 0 0 5px #e5e1e1;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .el-pagination {
        margin-top: 55px;
        margin-left: 50%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .cooperation {
    .head {
      height: 400px !important;
    }
    .banner {
      .carousel-main {
        .left {
          display: none;
        }
      }
      .toggle {
        gap: 0;
      }
    }
    .partner {
      .main {
        .main-top {
          gap: 20px;
          .item {
            width: 26%;
          }
        }
      }
    }
  }
}
</style>
